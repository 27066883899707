(function (global, factory) {
    factory(require('numeral'));
}(this, function (numeral) {
    var es = {
        delimiters: {
            thousands: '.',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'mm',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            var b = number % 10;
            return (b === 1 || b === 3) ? 'er' :
                (b === 2) ? 'do' :
                    (b === 7 || b === 0) ? 'mo' :
                        (b === 8) ? 'vo' :
                            (b === 9) ? 'no' : 'to';
        },
        currency: {
            symbol: 'EUR'
        }
    };

    var fr = {
        delimiters: {
            thousands: '.',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't'
        },
        ordinal : function (number) {
            return number === 1 ? 'er' : 'e';
        },
        currency: {
            symbol: 'EUR'
        }
    };

    var en = {
        delimiters: {
            thousands: ',',
            decimal: '.'
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            var b = number % 10;
            return (~~ (number % 100 / 10) === 1) ? 'th' :
                (b === 1) ? 'st' :
                (b === 2) ? 'nd' :
                (b === 3) ? 'rd' : 'th';
        },
        currency: {
            symbol: 'EUR'
        }
    };

    var enUS = Object.assign({}, numeral.locales['en'])

    // NOTE: Numeral throws an error trying to register an already registered locale. This is a workaround to bypass it
    delete numeral.locales['en']
    numeral.register('locale', 'ca', es);
    numeral.register('locale', 'es', es);
    numeral.register('locale', 'eu', es);
    numeral.register('locale', 'fr', fr);
    numeral.register('locale', 'en', en);
    numeral.register('locale', 'en-us', enUS);
}));
