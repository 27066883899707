/*global App : true*/

define([
    "expose-loader?$!expose-loader?jQuery!jquery",
    "underscore",
    "backbone",
    "local-router",
    "components/modal",
    "components/modal-question",
    "components/noti",
    "components/header",
    "components/cookie-noticer",
    "i18next/i18next",
    "models/user",
    "moment",
    "components/yii-js-router",
    "utils/number-formatter",
    "utils/reporter",
    "moment/es",
    "backbone.overrides",
    "datetimepicker/jquery.datetimepicker",
    // "imports?jQuery=jquery!lib/jquery.lazyload/jquery.lazyload"
], function($, _, Backbone, LocalRouter, Modal, ModalQuestion, Noti, Header, CookieNoticer, i18n, UserModel, moment, YiiJsRouter, NumberFormatter, Reporter) {
        "use strict";
        Reporter.init();
        var $body = $("body");
        App.YiiJsRouter = new YiiJsRouter();
        App.urlTo = function(path, params) {
            return App.YiiJsRouter.generateUrl(path, params);
        };
        App.urlRegexTo = function(path, params) {
            return App.YiiJsRouter.generateRegexUrl(path, params);
        };
        App.asDecimal = function(number, precision) {
            return NumberFormatter.asDecimal(number, precision);
        };
        App.parseFloat = function(number) {
            return NumberFormatter.parseFloat(number);
        };
        App.toCurrency = function(number, currency, options) {
            return NumberFormatter.toCurrency(number, currency, options);
        };
        App.parseInt = function(number) {
            return NumberFormatter.parseInt(number);
        };
        App.Header = new Header();
        App.LocalRouter = new LocalRouter();
        App.Modal = new Modal();
        App.ModalQuestion = new ModalQuestion();
        App.CookieNoticer = new CookieNoticer();
        App.Modals = {};
        App.Events = _.extend({}, Backbone.Events);
        App.User = new UserModel({
            html: $(".js-user")
        }, { parse: true });
        App.Noti = new Noti();

        Backbone.history.start({
            hashChange: false,
            pushState: true,
            root: "/",
        });

        _.each($(".js-app-flash"), function(flash) {
            var $alert = $(flash);
            App.Noti.show($alert.text(), $alert.attr("data-type"));
        });

        $body.removeClass("is-loading no-js").addClass("js-loaded");

        if ($.fn.lazyload) {
            $(".js-lazy-image").lazyload();
        }

        //i18n
        i18n.init({
            lng: App.LANGUAGE,
            resGetPath: "/locales/__ns__-__lng__.json",
            getAsync: true,
            fallbackLng: "es",
            useCookie: true,
            useLocalStorage: false,
            lowerCaseLng: true,
        });

        // Moment
        moment.locale(App.LANGUAGE);

        //Datetimepicker
        $.datetimepicker.setLocale(App.LANGUAGE);

        $.datetimepicker.setDateFormatter({
            parseDate: function (date, format) {
                var d = moment(date, format);
                return d.isValid() ? d.toDate() : false;
            },

            formatDate: function (date, format) {
                return moment(date).format(format);
            },
        });

        Backbone.$.ajaxSetup({
            headers: {
                "Accept-Language" : App.LANGUAGE
            }
        });

    });
