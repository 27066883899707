define([
    "jquery",
    "underscore",
    "backbone",
    "utils/mobile-checker",
    "lib/selectize/selectize"
    ], function($, _, Backbone, MobileChecker) {
        "use strict";

        var SelectizeDropdown = Backbone.View.extend({

            initialize: function(options) {
                this.autoRefresh = true;
                options = options || {};


                if (options.$el.length) {
                    this.$el = options.$el;

                    if (!MobileChecker.isMobile()) {

                        options.settings = _.extend({
                            nullable: this.$el.attr("data-nullable") === "1",
                            onInitialize: function() {
                                var s = this;
                                this.revertSettings.$children.each(function() {
                                    $.extend(s.options[this.value], $(this).data());
                                });
                            },
                            render: {
                                option: function(data, escape) {
                                    return this.optionTemplate ? this.optionTemplate(data) : "<div class='option " + (data.parent ? "is-parent" : "") + "'>" + escape(data.text) + "</div>";
                                }.bind(this)
                            },
                            load: function(query, callback) {
                                if (this.autoRefresh) {
                                    if (this.collection && query.length > 3) {
                                        this.fetchResults(query, callback);
                                    } else {
                                        callback();
                                    }
                                } else {
                                    callback();
                                }
                            }.bind(this)
                        }, options.settings);

                        this.$el
                            .find("select")
                            .selectize(options.settings);
                    }
                }

                this.content_property = this.$el.attr("data-item-label-key") !== undefined ?  this.$el.attr("data-item-label-key") : "name";
                this.value_property = this.$el.attr("data-item-value-key") !== undefined ?  this.$el.attr("data-item-value-key") : "id";

                this.$el.data("dropdown", this);
            },

            instance: function() {
                var instance = this.$el.find("select");
                if (!MobileChecker.isMobile()) {
                    return instance[0].selectize;
                }
                return instance;
            },

            fetchResults: function(query, callback) {
                if (this.collection) {
                    this.search(query, callback);
                }
            },

            search: function(query, callback = null) {
                this.collection.reset();
                this.collection.search(query, function () {
                    this.instance().refreshOptions();
                    callback && callback(this.collection);
                }.bind(this));
            },

            destroy: function() {
                if (!MobileChecker.isMobile()) {
                    this.instance().destroy();
                }
            },

            getValue: function() {
                if (!MobileChecker.isMobile()) {
                    return this.instance().getValue();
                } else {
                    return this.instance().val();
                }
            },

            setValue: function(value) {
                if (!MobileChecker.isMobile()) {
                    if (_.isEmpty(this.instance().options)) {
                        this.futureValue = value;
                    } else {
                        this.instance().setValue(value);
                    }

                } else {
                    this.instance().val(value);
                }
            },


            /**
             * This is only to support previous "API" of dropdown
             */
            val: function(value) {
                if (value !== undefined) {
                    this.setValue(value);
                } else {
                    return this.getValue();
                }
            },

            setCollection: function(collection, autoRefresh) {

                this.autoRefresh = autoRefresh !== undefined ? autoRefresh : true;

                if (!this.collection) {
                    this.collection = collection;
                    this.listenTo(collection, "reset", this.clearOptions.bind(this, collection));
                    this.listenTo(collection, "add", this.addOption.bind(this));
                }
            },

            setOptionTemplate: function(fnTemplate) {
                this.optionTemplate = fnTemplate;
            },

            clearOptions: function(collection) {

                this.setValue("");
                if (!MobileChecker.isMobile()) {
                    this.instance().clearOptions();
                } else {
                    this.instance().empty();
                }

                if (collection && collection.models.length) {
                    _.each(collection.models, function(model) {
                        this.addOption(model);
                    }.bind(this));

                    if (this.futureValue) {
                        this.setValue(this.futureValue);
                        this.futureValue = null;
                    }
                }
            },

            getOptions: function() {
                if (!MobileChecker.isMobile()) {
                    return this.instance().options;
                } else {
                    var options = {};
                    this.$el.find("option").map(function(index, option) {
                        var $option = $(option);
                        options[ $option.attr("value") ] = $option.data();
                    });

                    return options;
                }
            },

            getTextContent: function(model) {
                return model.get(this.content_property);
            },

            searchValue: function(value) {
                var input = this.$el.find('.selectize-input input')
                if (!input) {
                    return
                }
                return this.$el.find('.selectize-input input').val(value)
            },

            onSearchEvent: function(event, callback) {
                var input = this.$el.find('.selectize-input input')
                if (!input) {
                    return
                }
                return this.$el.find('.selectize-input input').on(event, callback)
            },

            getValueContent: function(model) {
                return model.get(this.value_property);
            },

            addOption: function(model) {

                var modelData = model.toJSON();
                modelData.value = this.getValueContent(model);
                modelData.text = this.getTextContent(model);

                if (!MobileChecker.isMobile()) {
                    this.instance().addOption(modelData);
                } else {
                    this.instance().append(
                        $('<option></option>')
                            .val(modelData.value)
                            .html(modelData.text));
                }
            },

            removeOption: function(value) {
                this.setValue('');
                if (!MobileChecker.isMobile()) {
                    this.instance().removeOption(value);
                } else {
                    this.instance().find('option[value="'+value+'"]').remove();
                }
            },

            enable: function() {
                if (!MobileChecker.isMobile()) {
                    this.instance().enable();
                } else {
                    this.instance().attr("disabled", null);
                }
            },

            disable: function() {
                if (!MobileChecker.isMobile()) {
                    this.instance().disable();
                } else {
                    this.instance().attr("disabled", "disabled");
                }
            },


            focus: function() {
                window.setTimeout(function() {
                    this.instance().focus();
                }.bind(this), 100);
            },

            on: function(event, callback) {
                this.instance().on(event, function(value) {
                    if (!this.collection){
                        callback(value);
                    } else {
                        callback(value, this.collection.get(value));
                    }
                }.bind(this));
            }
        });

        return SelectizeDropdown;

    });
