define([
    'underscore',
    'numeral',
    'utils/numeral-locales',
], function(_, numeral) {
    "use strict";

    var NumberFormatter = {

        fractionDigits: {
            minimum: 2,
            maximum: 2,
        },

        currencyFractionDigits: {
            EUR: 2,
            CRC: 0,
            USD: 2,
        },

        /**
         * @deprecated
         */
        asDecimal: function(number, precision) {
            precision = precision || 2;
            var numberToFormat = parseFloat(parseFloat(number).toFixed(precision));
            return numberToFormat.toLocaleString('es', {
                minimumFractionDigits: 2
            });
        },

        /**
         * @deprecated
         */
        parseFloat: function(number) {
            if (isNaN(number)) {
                return parseFloat(number.replace(/\./g, "").replace(",", "."));
            } else {
                return parseFloat(number);
            }
        },

        parseFloatString: function(number, config) {
            if (!NumberFormatter.validate(number, config)) {
                return NaN
            }
            numeral.locale(App.LANGUAGE.toLowerCase());
            return numeral(number).value();
        },

        validate: function(number, config) {
            if (!number) {
                return false
            }
            return this.getNumberRegex(config || App.Constants.NUMBER_FORMAT).test(number);
        },

        parseInt: function(number) {
            var val = this.parseFloat(number)
            if (!Number.isInteger(val)) {
                return NaN
            }
            return Number.parseInt(val)
        },

        toDecimal: function (number, options) {
            options = options || {};
            var locale = options.locale || App.LANGUAGE
            var formatOptions = _.defaults(options, {
                minimumFractionDigits: this.fractionDigits.minimum,
                maximumFractionDigits: this.fractionDigits.maximum
            })

            var formatter = new Intl.NumberFormat(locale, formatOptions);

            if (number === null) {
                return ''
            }

            return formatter.format(number)
        },

        /**
         * @param number
         * @param currency
         * @param options
         *   - only_symbol: return only the currency symbol, without number
         *   - with_symbol: return number with currency symbol
         *   - fractionDigits: precision
         */
        toCurrency: function(number, currency, options) {
            var ops = options || {}
            var locale = ops.locale || App.LANGUAGE

            var formatter = new Intl.NumberFormat(locale, {
                minimumFractionDigits: ops.fractionDigits || this.currencyFractionDigits[currency],
                maximumFractionDigits: ops.fractionDigits || this.currencyFractionDigits[currency]
            });

            if (number === null) {
                return ''
            }

            var formattedNumber = formatter.format(number)

            if (ops.with_symbol) {
                return formattedNumber + ' ' + currency;
            }

            if (ops.only_symbol) {
                return currency
            }

            return formattedNumber
        },


        getNumberRegex: function (config) {
            var decimalSeparator = config ? config.DECIMAL_SEPARATOR_SYMBOL : ',';
            var thousandSeparator = config ? config.THOUSAND_SEPARATOR_SYMBOL : '.';
            return new RegExp("^-?\\d+(\\" + thousandSeparator + "\\d{3})?(\\" + decimalSeparator + "\\d+)?$");
        }
    };

    return NumberFormatter;
});
