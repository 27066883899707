define([
    "@sentry/browser",
], function(Sentry) {
    "use strict";

    const developmentEnvironments = [
        'local',
        'test',
    ]

    var Reporter = {
        init() {
            this.active() && Sentry.init({
                dsn: App.Constants.SENTRY_URI,
                environment:  App.Constants.YII_ENV,
            })
        },

        active () {
            return App.Constants.SENTRY_URI && !developmentEnvironments.includes(App.Constants.YII_ENV)
        }
    };

    return Reporter;
});

