    define([
    "jquery",
    "underscore",
    "backbone",
    "utils/logger",
    ], function($, _, Backbone, Logger) {
        "use strict";

        return Backbone.Router.extend({

            routings: null,

            initialize: function() {
                this.routings = [];

                //Login
                this.routings.push({ key: "accountLogin", path: new RegExp(App.urlRegexTo("account/login") + ".*$"), view: "views/account/login" });

                //Account
                this.routings.push({ key: "accountRegister", path: new RegExp(App.urlRegexTo("user/account/register") + ".*$"), view: "views/account/register" });
                this.routings.push({ key: "accountEdit", path: new RegExp(App.urlRegexTo("user/account/edit") + ".*$"), view: "views/account/edit" });
                this.routings.push({
                    key: "accountConfirm",
                    path: new RegExp(App.urlRegexTo("user/account/confirm")),
                    view: "views/account/account-confirm"
                });

                // Remember password
                this.routings.push({ key: "requestPasswordReset", path: new RegExp(App.urlRegexTo("user/account/request-password-reset") + ".*$"), view: "views/account/request-password-reset" });
                this.routings.push({ key: "resetPassword", path: new RegExp(App.urlRegexTo("user/account/reset-password") + ".*$"), view: "account/reset-password" });

                //Empty routes
                this.routings.push({ key: "error", path: /error$/ });

                // Change email
                this.routings.push({
                    key: "parkingShow",
                    path: new RegExp(App.urlRegexTo("user/account/update-email") + ".*$"),
                    view: "views/account/account-confirm"
                });
                //Parking
                //Control panel
                this.routings.push({ key: "parkingShow", path: new RegExp(App.urlRegexTo("parking/update") + ".*$"), view: "parking/show" });
                this.routings.push({ key: "controlPanel", path: new RegExp(App.urlRegexTo("parking/control-panel") + ".*$"), view: "parking/control-panel" });
                this.routings.push({ key: "controlPanel", path: new RegExp(App.urlRegexTo("parking/update")), view: "parking/control-panel" });

                // SeasonTicket Contracts
                this.routings.push({ key: "seasonTicketList", path: new RegExp(App.urlRegexTo("season-ticket/list") + ".*$"), view: "season-ticket/list" });
                this.routings.push({ key: "seasonTicketContracts", path: new RegExp(App.urlRegexTo("season-ticket-contract/list") + ".*$"), view: "season-ticket-contract/list" });
                this.routings.push({ key: "parkingContracts", path: new RegExp(App.urlRegexTo("contract/contracts") + ".*$"), view: "season-ticket-contract/list" });
                this.routings.push({ key: "parkingContracts", path: new RegExp(App.urlRegexTo("contract/contract") + ".*$"), view: "season-ticket-contract/list" });

                // Parking info
                this.routings.push({ key: "infoGenenral", path: new RegExp(App.urlRegexTo("parking/info") + "/general.*$"), view: "info/general" });
                this.routings.push({ key: "infoTimetable", path: new RegExp(App.urlRegexTo("parking/info") + "/timetable.*$"), view: "info/timetable" });
                this.routings.push({ key: "infoGallery", path: new RegExp(App.urlRegexTo("parking/info") + "/gallery.*$"), view: "info/gallery" });
                this.routings.push({ key: "infoHolidays", path: new RegExp(App.urlRegexTo("parking/info") + "/holiday.*$"), view: "info/holiday" });
                this.routings.push({ key: "infoClosed", path: new RegExp(App.urlRegexTo("parking/info") + "/closing.*$"), view: "info/closing" });
                this.routings.push({ key: "infoServices", path: new RegExp(App.urlRegexTo("parking/info") + "/services.*$"), view: "info/services" });
                this.routings.push({ key: "infoExtraServices", path: new RegExp(App.urlRegexTo("parking/info") + "/extra-services"), view: "info/extra-services" });
                this.routings.push({ key: "infoGroup", path: new RegExp(App.urlRegexTo("parking/get-group")), view: "group-detail/group-detail" });

                // Installations
                this.routings.push({ key: "zoneEdit", path: new RegExp(App.urlRegexTo("parking/zone-list") + ".*$"), view: "installations/zones" });
                this.routings.push({ key: "installationsDoors", path: new RegExp(App.urlRegexTo("parking/door-list") + ".*$"), view: "installations/door-list" });
                this.routings.push({ key: "installationsMonitoredDevices", path: new RegExp(App.urlRegexTo("monitored-devices/list") + ".*$"), view: "installations/monitored-device-list" });

                // Stay
                this.routings.push({ key: "stayList", path: new RegExp(App.urlRegexTo("occupancy/actual") + ".*$"), view: "occupancy/list" });
                this.routings.push({ key: "stayList", path: new RegExp(App.urlRegexTo("occupancy/historical") + ".*$"), view: "occupancy/list" });

                // Parking Available Booking List
                this.routings.push({ key: "parkingAvailableBookingList", path: new RegExp(App.urlRegexTo("parking-available-booking-list/view") + ".*$"), view: "views/commons/empty" })
                this.routings.push({ key: "parkingAvailableBookingListVechilces", path: new RegExp(App.urlRegexTo("parking-available-booking-list-vehicle/get-by-list") + ".*$"), view: "parking-available-booking-list-vehicle/list" })

                // Parking Available Rotation List
                this.routings.push({ key: "parkingAvailableRotationListVechilces", path: new RegExp(App.urlRegexTo("parking-available-rotation-list-vehicle/get-by-list") + ".*$"), view: "parking-available-rotation-list-vehicle/list" })
                this.routings.push({ key: "parkingAvailableRotationList", path: new RegExp(App.urlRegexTo("parking-available-rotation-list/list") + ".*$"), view: "parking-available-rotation-list/list" })

                // Booking
                this.routings.push({ key: "bookingList", path: new RegExp(App.urlRegexTo("booking/list-actives") + ".*$"), view: "booking/list" });
                this.routings.push({ key: "bookingList", path: new RegExp(App.urlRegexTo("booking/list-inactives") + ".*$"), view: "booking/list" });

                // BookingProduct
                this.routings.push({ key: "bookingConfig", path: new RegExp(App.urlRegexTo("booking-product/list-actives") + ".*$"), view: "booking-product/list" });
                this.routings.push({ key: "bookingProductDetail", path: new RegExp(App.urlRegexTo("booking-product/show") + ".*$"), view: "booking-product/detail" });

                // Rotations
                this.routings.push({ key: "rotationList", path: new RegExp(App.urlRegexTo("rotation/list-actives") + ".*$"), view: "rotation/list" });
                this.routings.push({ key: "rotationList", path: new RegExp(App.urlRegexTo("rotation/list-inactives") + ".*$"), view: "rotation/list" });
                this.routings.push({ key: "rotationConfig", path: new RegExp(App.urlRegexTo("rotation/config") + ".*$"), view: "rotation/config" });
                this.routings.push({ key: "rotationTrackConfig", path: new RegExp(App.urlRegexTo("rotation-rate-timetable-truck/config") + ".*$"), view: "rotation-rate-timetable-truck/config" });

                // External rotations
                this.routings.push({ key: "externalRotationList", path: new RegExp(App.urlRegexTo("external-rotation/list-actives") + ".*$"), view: "external-rotation/list" });
                this.routings.push({ key: "externalRotationList", path: new RegExp(App.urlRegexTo("external-rotation/list-inactives") + ".*$"), view: "external-rotation/list" });

                // External booking
                this.routings.push({ key: "externalBookingList", path: new RegExp(App.urlRegexTo("external-booking/list-actives") + ".*$"), view: "external-booking/list" });
                this.routings.push({ key: "externalBookingList", path: new RegExp(App.urlRegexTo("external-booking/list-inactives") + ".*$"), view: "external-booking/list" });
                this.routings.push({ key: "externalBookingDetail", path: new RegExp(App.urlRegexTo("external-booking/detail") + ".*$"), view: "external-booking/list" });

                // Availability
                this.routings.push({ key: "availabilityShow", path: new RegExp(App.urlRegexTo("availability/show") + ".*$"), view: "availability/show" });

                // Statistics
                this.routings.push({ key: "statistics", path: new RegExp(App.urlRegexTo("statistics/show") + ".*$"), view: "statistics/show" });

                // Rates
                this.routings.push({ key: "parkingRates", path: new RegExp(App.urlRegexTo("parking-rate/list") + ".*$"), view: "parking-rate/list" });
                this.routings.push({ key: "parkingRateShow", path: new RegExp(App.urlRegexTo("parking-rate/index") + ".*$"), view: "parking-rate/show" });

                // Liquidation
                this.routings.push({ key: "liquidationList", path: new RegExp(App.urlRegexTo("liquidation/list") + ".*$"), view: "liquidation/list" });

                // AutoInvoice
                this.routings.push({ key: "autoInvoice", path:  new RegExp(App.urlRegexTo("auto-invoice/show")), view: "auto-invoice/show" });

                this.routings.push({ key: "userPreferences", path:  new RegExp(App.urlRegexTo("account/preferences")), view: "views/commons/empty" });
                this.routings.push({ key: "parkingList", path:  new RegExp(App.urlRegexTo("parking/list")), view: "views/commons/empty" });

                // ParkingPass
                this.routings.push({ key: "offeredParkingPass", path: new RegExp(App.urlRegexTo("offered-parking-pass/list") + ".*$"), view: "offered-parking-pass/list" });
                this.routings.push({ key: "offeredParkingPassEdit", path: new RegExp(App.urlRegexTo("offered-parking-pass/edit") + ".*$"), view: "offered-parking-pass/list" });

                this.routings.push({ key: "bookedParkingPass", path: new RegExp(App.urlRegexTo("booked-parking-pass/list") + ".*$"), view: "booked-parking-pass/list" });
                this.routings.push({ key: "bookedParkingPassEdit", path: new RegExp(App.urlRegexTo("booked-parking-pass/edit") + ".*$"), view: "booked-parking-pass/list" });

                // Excess rate
                this.routings.push({ key: "excessRateConfig", path: new RegExp(App.urlRegexTo("excess-rate/config") + ".*$"), view: "excess-rate/config" })

                this.generateRoutes();
            },

            empty: function() {},

            createView: function(key, view, args) {
                Logger.log(key + " - " + view);

                if (view){
                    var router = this;
                    if (/^views/.test(view)) {
                        require([ "views/" + view.replace("views/", "") ], function(View) {
                            /**
                                The reloadPage function is invoked here to prevent
                                a bucle of reloads when page is reloaded
                            */
                            router.on("route", router.reloadPage);
                            if (!App.Views[ view ]) {
                                var ViewHelper = (function() {
                                    return function ViewHelper() {
                                        return View.apply(this, args);
                                    };
                                })();
                                ViewHelper.prototype = View.prototype;

                                App.Views[ view ] = new ViewHelper(args);
                                App.Views[ view ].render();
                            }
                        });
                    } else {
                        require([ "pages/" + view ], function(View) {
                            /**
                                The reloadPage function is invoked here to prevent
                                a bucle of reloads when page is reloaded
                            */
                            router.on("route", router.reloadPage);
                            if (!App.Views[ view ]) {
                                var ViewHelper = (function() {
                                    return function ViewHelper() {
                                        return View.apply(this, args);
                                    };
                                })();
                                ViewHelper.prototype = View.prototype;

                                App.Views[ view ] = new ViewHelper(args);
                                App.Views[ view ].render();
                            }
                        });
                    }

                }
            },

            reloadPage: function() {
                window.location.reload();
            },

            generateRoutes: function() {
                _.each(this.routings, function(r) {
                    r.path = new RegExp("(?:^|index.php\/)" + r.path.source);
                    this.route(r.path, r.key, function() { this.createView(r.key, r.view, arguments); }.bind(this));
                }, this);
            }

        });

    });
